import "aos/dist/aos.css";
import AppStoreButton from "./AppStoreButton";
import GooglePlayButton from "./GooglePlayButton";
import { getStartedData } from "../utils/data";
import { useState } from "react";

type GetStartedCardProps = {
  title: string;
  description: string;
};
const GetStartedCard = ({ title, description }: GetStartedCardProps) => {
  return (
    <div
      data-aos="fade-up"
      className="bg-black-50 border-2 border-white shadow-sm py-8 px-5  shadow-smf rounded-xl md:px-7 md:w-[410px]"
    >
      <p className="mb-5 text-xl font-semibold text-primary-500">{title}</p>
      <p className="text-black-400">{description}</p>
    </div>
  );
};

const GetStarted = () => {
  const [isLive] = useState(true);
  return (
    <section
      id="get-started"
      className="px-4 py-20 bg-black-100 md:rounded-2xl md:px-10"
    >
      <h3 className="mb-10 text-xl font-bold text-center text-black-500 sm:text-2xl md:text-3xl lg:text-4xl ">
        Get Started
      </h3>

      <div className="flex flex-col justify-center gap-5 md:flex-row md:flex-wrap md:items-center md:gap-6 lg:gap-8">
        {getStartedData?.map((item) => (
          <GetStartedCard
            key={item?.id}
            title={item?.title}
            description={item?.description}
          />
        ))}
      </div>
      {isLive ? (
        <div
          data-aos="fade-up"
          className="flex items-center justify-center gap-2 mx-auto mt-16 sm:gap-4"
        >
          <AppStoreButton />
          <GooglePlayButton />
        </div>
      ) : (
        <p className="mt-10 text-xl font-medium text-center text-primary-500 lg:text-2xl">
          Launching Soon...
        </p>
      )}
    </section>
  );
};

export default GetStarted;
