import { ReactComponent as AppStoreIcon } from "../assets/SVGs/apple-logo.svg";
import { Link } from "react-router-dom";

const AppStoreButton = () => {
  return (
    <Link
      to="https://apps.apple.com/us/app/travaapp/id1644446183"
      target="_blank"
      className="flex items-center gap-3 px-5 py-2 transition duration-150 border-none outline-none bg-primary-500 text-black-50 w-44 rounded-xl justify-betweenf sm:w-52 sm:px-8 sm:gap-4 "
    >
      <span className="w-5">
        <AppStoreIcon />
      </span>
      <p className="text-left">
        <span className="block text-xs md:text-sm">Download on the</span>
        <span className="text-sm font-bold sm:text-base">App Store</span>
      </p>
    </Link>
  );
};

export default AppStoreButton;
